export default {
  name: 'ProductDetail',
  props: {
    data: {
      'required': true,
      'type': Object
    },
    showPreviousBtn: {
      'required': false,
      'default': false,
      'type': Boolean
    },
    showNextBtn: {
      'required': false,
      'default': false,
      'type': Boolean
    }
  },
  emits: ['productDetailClose', 'changeBtnClick', 'productDetailViewPrev', 'productDetailViewNext'],
  data: function data() {
    return {
      loading: false,
      loadingProductChange: false,
      error: false
    };
  },
  methods: {
    handleClose: function handleClose() {
      this.$emit('productDetailViewClose');
    }
  },
  computed: {},
  mounted: function mounted() {}
};