import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_SubDetailPanel = _resolveComponent("SubDetailPanel");

  var _component_ProductErr = _resolveComponent("ProductErr");

  return $data.id ? (_openBlock(), _createBlock(_component_SubDetailPanel, {
    key: 0,
    id: $data.id,
    isFsId: $data.isFsId
  }, null, 8, ["id", "isFsId"])) : (_openBlock(), _createBlock(_component_ProductErr, {
    key: 1,
    msg: "Nothing to see here (404)",
    style: {
      "padding-top": "150px"
    }
  }));
}