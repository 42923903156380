import SubDetailPanel from '../components/SubDetailPanel/SubDetailPanel';
import ProductErr from '../components/ProductErr.vue';
export default {
  name: 'SubscriptionDetail',
  components: {
    SubDetailPanel: SubDetailPanel,
    ProductErr: ProductErr
  },
  data: function data() {
    return {
      id: null,
      isFsId: false
    };
  },
  watch: {
    '$route.params': {
      handler: function handler(params) {
        if (params.id) {
          this.id = this.$route.params.id;
          this.isFsId = !!this.$route.query.fs_id;
        }
      }
    }
  },
  mounted: function mounted() {
    this.id = this.$route.params.id;
    this.isFsId = !!this.$route.query.fs_id;
  }
};